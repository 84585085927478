import React, {useContext, useEffect, useState} from 'react';
import {FirebaseContext} from '../components/Firebase';
import {Form, Input, Button, TextField} from '../components/common';
import styled from 'styled-components';

const FormField = styled.div`
  margin-bottom: 20px;
`

let fileReader;
if(typeof window !== 'undefined'){
  fileReader = new FileReader();
}

const AddProduct = () => {
  const {firebase} = useContext(FirebaseContext);
  const [brands, setBrands] = useState([]);
  const [mainImage, setmainImage] = useState('');
  const [productName, setproductName] = useState('');
  const [brandId, setbrandId] = useState('');
  const [content, setcontent] = useState('');
  const [success, setSuccess] = useState(false);

  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    }
  }, [])

  useEffect(() => {
    fileReader.addEventListener('load', () => {
      setmainImage(fileReader.result);
    })
  }, []);

  useEffect(() => {
    // query all available brand
    if(firebase) {
      firebase.getBrands().then(snapshot => {
        if(isMounted) {
          const availableBrands = [];
          snapshot.forEach(doc => {
            availableBrands.push({
              id: doc.id,
              ...doc.data()
            })
          })

          setbrandId(availableBrands[0].id);

          setBrands(availableBrands);
        }
      })
    }
  }, [firebase])
 
  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      firebase.createProduct({
        mainImage,
        productName,
        brandId,
        content
      }).then(() => {
        if(isMounted) {
          setSuccess(true)
        }
      })
    }}>
      <FormField>
        <Input required placeholder="Product name" value={productName} onChange={e => {
          e.persist();
          setSuccess(false);
          setproductName(e.target.value)
        }} />
      </FormField>
      <FormField>
        <strong>
          Brand
        </strong>
        <div>
          <select value={brandId} onChange={e => {
            e.persist();
            setSuccess(false);
            setbrandId(e.target.value)
          }}>
            {brands.map(a => (
              <option key={a.id} value={a.id}>
                {a.brand}
              </option>
            ))}
          </select>
        </div>
      </FormField>
      <FormField>
        <strong>
          Main Image
        </strong>
        <Input required type="file" onChange={e => {
          e.persist();
          setSuccess(false);
          fileReader.readAsDataURL(e.target.files[0])
        }} />
      </FormField>
      <FormField>
        <strong>
          Content
        </strong>
        <TextField value={content}
                   onChange={e => {
                    //setSuccess(false);
                    setcontent(e)
                  }} />
      </FormField>
      
      {!!success &&
        <span>
          New book added successfully!
        </span>
      }
      <Button block type="submit">
        Add new product
      </Button>
    </Form>
  );
}

export default AddProduct;